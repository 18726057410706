type Props = {
  wrapperStyle?: string,
  textStyle?: string,
  rows?: {
    title?: string,
  }[],
};

export default function FooterIntroduceRows(props: Props) {
  const { wrapperStyle, rows } = props;
  return (
    <div className={`${wrapperStyle}`}>
      {rows.map((item, index) => {
        return (
          <div
            key={index}
            className="text-12 text-white text-center leading-relaxed">
            {item.title}
          </div>
        );
      })}
    </div>
  );
}
